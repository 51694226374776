<template>
  <div class="regulation-text">
    <h2>
      Regulamin świadczenia usług dla Klientów {{ companyName }} z dnia …..
    </h2>
    <div v-if="mode === 'PZU'">
      <h3>§ 1. Postanowienia ogólne</h3>

      <p>
        1. Właścicielem Portalu jest Homedoctor Sp. z o.o. z siedzibą w
        Warszawie, ul. Hoża 86/410, 00-682 Warszawa wpisana do rejestru
        przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd
        Rejonowy dla m.st. Warszawy w Warszawie, XII Wydział Gospodarczy KRS pod
        numerem 0000636394, NIP: 946-266-27-63, REGON: 36536014100000, wysokość
        kapitału zakładowego 5 000 zł (dalej jako: „HomeDoctor”).
      </p>
      <p>
        2. Świadczeniodawcą jest podmiot leczniczy Doc4You sp. z o.o., z
        siedzibą w Warszawie przy al. Jerozolimskich 142A, kod pocztowy 02-305,
        wpisany do rejestru przedsiębiorców Krajowego Rejestru Sądowego
        prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XII
        Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS:
        0000614105, NIP: 5252656702, REGON: 36427266000000, wysokość kapitału
        zakładowego 5 000 zł, wpisany do Rejestru Podmiotów Wykonujących
        Działalność Leczniczą, prowadzonego przez Wojewodę Mazowieckiego nr
        księgi 000000206097 (dalej jako: „Świadczeniodawca”)
      </p>
      <p>
        3. Regulamin określa warunki i zasady świadczenia przez Świadczeniodawcę
        Usług medycznych zamawianych za pośrednictwem Portalu na rzecz
        Uprawnionego.
      </p>
      <p>
        4. Home Doctor udostępnia Regulamin Uprawnionemu w formularzu Zamówienia
        oraz na stronie https://........ w sposób gwarantujący jego pobranie,
        wydrukowanie oraz utrwalenie jego treści.
      </p>
      <p>
        5. Do Regulaminu zastosowanie mają w szczególności następujące akty
        prawne:
      </p>
      <ul>
        <li>a. Ustawa z dnia 16 lipca 2004 r. prawo telekomunikacyjne;</li>
        <li>
          b. Ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą
          elektroniczną;
        </li>
        <li>
          c. Ustawa z dnia 15 kwietnia 2011 r. o działalności leczniczej;
        </li>
        <li>
          d. Ustawa z dnia 6 listopada 2008 r. o prawach pacjenta i Rzeczniku
          Praw Pacjenta;
        </li>
        <li>
          e. Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
          27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
          przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
          o ochronie danych);
        </li>
        <li>f. Ustawa z dnia 23 kwietnia 1964 r. - Kodeks cywilny.</li>
      </ul>
      <p>
        6. Akceptując Regulamin, Uprawniony uwzględnia okoliczność, że Usługi
        świadczone są za pomocą środków komunikacji elektronicznej, a w
        przypadku podania numeru telefonu przez Uprawnionego – wyraża również
        zgodę na komunikację telefoniczną, prowadzoną przez Świadczeniodawcę w
        celu realizacji Usługi medycznej oraz w celu przekazywania informacji o
        zmianach Regulaminu.
      </p>
      <p>
        7. W sprawach nieuregulowanych w Regulaminie, zastosowanie mają przepisy
        prawa powszechnie obowiązującego.
      </p>

      <h3>§ 2. Definicje</h3>

      <p>1. Przez użyte w Regulaminie pojęcia rozumie się:</p>
      <ul>
        <li>
          a. Usługi Telemedyczne – konsultacje medyczne wykonywane przez
          Świadczeniodawcę na rzecz Uprawnionego na odległość - bez
          bezpośredniego badania przedmiotowego, przy pomocy przekazu, który
          jest w całości nadawany, odbierany lub transmitowany za pomocą
          systemów teleinformatycznych lub systemów łączności (odpowiednio w
          formie czatu, wideokonsultacji lub konsultacji telefonicznej,
          obsługiwanych za pośrednictwem Portalu lub telefonu);
        </li>
        <li>
          b. Hasło – ciąg znaków służący do uzyskania autoryzowanego dostępu do
          Portalu oraz używany do identyfikacji Uprawnionego przy świadczeniu
          Usług, ustalany samodzielnie przez osobę dokonującą rejestracji bądź
          nadawany automatycznie przez system podczas rejestracji Uprawnionego,
          o długości co najmniej 8 znaków, zawierający małe i wielkie litery
          oraz cyfry lub znaki specjalne;
        </li>
        <li>
          c. Uprawniony – wskazana przez Infolinię PZU Zdrowie pełnoletnia osoba
          fizyczna posiadająca pełną zdolność do czynności prawnych, która
          złożyła Zamówienie na wykonanie Usługi medycznej, a w przypadku
          zawarcia odpowiedniej Umowy o świadczenie usług medycznych – osoba, na
          rzecz której realizowana jest Usługa medyczna.
        </li>
        <li>
          d. Konsultant Medyczny – osoba fizyczna będąca podmiotem wykonującym
          działalność leczniczą zgodnie z przepisami ustawy o działalności
          leczniczej bądź też wykonująca pracę na rzecz podmiotu wykonującego
          działalność leczniczą, świadcząca Usługi Telemedyczne; Konsultanci
          udzielają świadczeń zdrowotnych na podstawie Regulaminu.
        </li>
        <li>
          e. Login - adres e-mail Uprawnionego, służący do identyfikacji
          Uprawnionego przez Świadczeniodawcę oraz do komunikacji z Uprawnionym.
          Uprawniony zobowiązany jest poinformować Świadczeniodawcę o zmianie
          adresu e-mail za pomocą właściwego formularza na Portalu.
        </li>
        <li>
          f. Portal – aplikacja dostępna pod adresem internetowym
          https://........, stanowiąca część systemu teleinformatycznego, na
          której tworzone są Indywidualne Konta Uprawnionych;
        </li>
        <li>
          g. Indywidualne Konto Uprawnionego – indywidualne i autoryzowane konto
          Uprawnionego chronione spersonalizowanym Loginem i Hasłem, aktywne w
          okresie posiadania uprawnień potwierdzonych przez PZU Zdrowie;
        </li>
        <li>h. Regulamin – niniejszy Regulamin</li>
        <li>
          i. Świadczeniodawca – Doc4You sp. z o.o., z siedzibą w Warszawie przy
          al. Jerozolimskich 142A, kod pocztowy 02-305, wpisany do rejestru
          przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd
          Rejonowy dla m.st. Warszawy w Warszawie, XII Wydział Gospodarczy
          Krajowego Rejestru Sądowego pod numerem KRS: 0000614105, NIP:
          5252656702, REGON: 36427266000000, wysokość kapitału zakładowego 5 000
          zł, wpisany do Rejestru Podmiotów Wykonujących Działalność Leczniczą,
          prowadzonego przez Wojewodę Mazowieckiego nr księgi 000000206097;
        </li>
      </ul>

      <h3>§ 3. Warunki świadczenia Usług Telemedycznych</h3>

      <p>
        1. Świadczeniodawca świadczy Usługi Telemedyczne drogą elektroniczną
        Uprawnionym, zgodnie z Regulaminem:
      </p>
      <ul>
        <li>a. w dni powszednie od 7:00 do 22:00,</li>
        <li>b. w soboty od 7:00 do 22:00,</li>
        <li>
          c. w niedziele i święta oraz dni ustawowo wolne od pracy od 7:00 do
          22:00.
        </li>
      </ul>
      <p>
        2. Usługi medyczne są realizowane po uprzedniej autoryzacji każdej
        Usługi przez infolinię PZU Zdrowie.
      </p>
      <p>
        3. W przypadku gwałtownego pogorszenia się stanu zdrowia lub
        samopoczucia, Uprawniony powinien niezwłocznie skontaktować się z
        numerem alarmowym 112 w celu wezwania pogotowia ratunkowego lub udać się
        do najbliższej placówki medycznej.
      </p>
      <p>
        4. Jeśli połączenie z Konsultantem Medycznym nie jest możliwe, jest
        utrudnione lub trwa zbyt długo, a stan zdrowia lub samopoczucie
        Uprawnionego pogarsza się, Uprawniony powinien niezwłocznie zasięgnąć
        pomocy w placówce medycznej.
      </p>
      <p>
        5. Kontakt bądź próba kontaktu z Konsultantem Medycznym nie mogą
        opóźniać lub zastąpić wykonania działań wskazanych w pkt 4 i 5.
      </p>
      <p>
        6. Komunikacja Uprawnionego z Konsultantem Medycznym w ramach Usługi
        Telemedycznej może przebiegać w zależności od decyzji Uprawnionego w
        formie:
      </p>
      <ul>
        <li>
          a. czatu – wymiany krótkich wiadomości tekstowych między Uprawnionym a
          Konsultantem Medycznym za pośrednictwem Portalu,
        </li>
        <li>
          b. telekonferencji – przekazu audio między Uprawnionym a Konsultantem
          Medycznym za pośrednictwem telefonu,
        </li>
        <li>
          c. wideokonferencji – przekazu audiowizualnego między Uprawnionym a
          Konsultantem Medycznym za pośrednictwem telefonu lub Portalu.
        </li>
      </ul>
      <p>
        7. Świadczeniodawca przed przystąpieniem do świadczenia Usługi
        Telemedycznej na rzecz Uprawnionego weryfikuje tożsamość Uprawnionego
        poprzez zwrócenie się o podanie imienia, nazwiska oraz numeru PESEL lub
        daty urodzenia.
      </p>
      <p>
        8. W celu wykonania Usług Telemedycznych Świadczeniodawca utworzy
        Uprawnionemu Indywidualne Konto Uprawnionego po pierwszej rejestracji
        Usługi Telemedycznej za pośrednictwem Infolinii PZU Zdrowie. Po
        utworzeniu Indywidualnego Konta Uprawnionego Świadczeniodawca przesyła
        do Uprawnionego wiadomością SMS i e-mailem spersonalizowany Login i
        Hasło wymagane do zalogowania się do Portalu, adres strony internetowej
        na której dostępny jest Regulamin oraz link, pod którym ma nastąpić
        pierwsze logowanie w celu wykonania Usługi Telemedycznej.
      </p>
      <p>
        9. W razie wyboru przez Uprawnionego usługi czatu lub wideokonferencji
        kontakt Uprawnionego z Konsultantem Medycznym nastąpi za pośrednictwem
        Indywidualnego Konta Uprawnionego na Portalu. W tym celu Uprawniony jest
        zobowiązany zalogować się do Indywidualnego Konta Uprawnionego w
        terminie potwierdzonym przez Świadczeniodawcę w wiadomości SMS lub
        e-mail przesłanej do Uprawnionego. W razie wyboru przez Uprawnionego
        usługi telekonferencji Konsultant Medyczny skontaktuje się telefonicznie
        z Uprawnionym w terminie potwierdzonym przez Świadczeniodawcę w
        wiadomości SMS lub e-mail przesłanej do Uprawnionego. Do kontynuowania
        usługi telekonferencji Uprawniony wyraża zgodę na przetwarzanie przez
        Świadczeniodawcę swoich danych osobowych.
      </p>
      <p>
        10. Świadczeniodawca zapewni Uprawnionym dostęp do Portalu 24 godziny na
        dobę przez 7 dni w tygodniu. Świadczeniodawca zastrzega sobie prawo do
        czasowego ograniczenia dostępności Portalu, gdy jest to niezbędne do
        przeprowadzenia niezbędnych prac konserwacyjnych i serwisowych. O
        wszystkich przypadkach ograniczenia dostępności Portalu Klienci będą
        informowani z odpowiednim wyprzedzeniem poprzez Indywidualne Konto
        Uprawnionego.
      </p>
      <p>
        11. Świadczeniodawca udostępnia Uprawnionemu na Portalu jego
        dokumentację medyczną, w tym w szczególności w zakresie co najmniej:
        historię udzielonych konsultacji, zaleceń pokonsultacyjnych, dokumentów
        udostępnionych przez Uprawnionego.
      </p>
      <p>
        12. Świadczeniodawca zapewni Uprawnionemu możliwość przesyłania
        dokumentów medycznych oraz wydruku zapisanych w Indywidualnym Koncie
        Uprawnionego dokumentów i danych medycznych.
      </p>
      <p>
        13. Uprawniony zobowiązuje się nie ujawniać osobom trzecim danych
        dostępowych do Indywidualnego Konta Uprawnionego. Uprawniony zobowiązuje
        się niezwłocznie poinformować Świadczeniodawcę poprzez e-mail
        pzu@diabdis.com o wszelkiego rodzaju naruszeniach bezpieczeństwa lub
        wypadku skorzystania z Indywidualnego Konta Uprawnionego przez osoby
        nieuprawnione.
      </p>
      <p>
        14. Uprawniony jest uprawniony przez 24 h po zakończeniu realizacji
        Usługi Telemedycznej do bezpłatnego jednorazowego zadania pytania w
        formie tekstowej Konsultantowi Medycznemu za pośrednictwem
        Indywidualnego Konta Uprawnionego lub wskazanego adresu e-mail.
      </p>
      <p>
        15. Jednostkowa Usługa Telemedyczna trwa 10 minut (jedna jednostka
        czasu).
      </p>
      <p>
        16. Jeżeli wymaga tego postawiona uprzednio diagnoza, Konsultant
        Medyczny w ramach Usługi Telemedycznej może wystawić receptę lub
        skierowanie niezbędne do kontynuacji leczenia/zaopatrzenia w wyroby
        medyczne, jeżeli jest to uzasadnione stanem zdrowia pacjenta
        odzwierciedlonym w dokumentacji medycznej. Dokument taki jest wystawiany
        w wersji elektronicznej (np. e-recepta, e-skierowanie)
      </p>
      <p>
        17. Wystawienie recepty wymaga przedłożenia Konsultantowi Medycznemu
        przez Uprawnionego dokumentów potwierdzających postawioną uprzednio
        diagnozę, między innymi jeden z niżej wymienionych dokumentów:
      </p>
      <ul>
        <li>a. Karta informacyjna z leczenia szpitalnego</li>
        <li>
          b. inne formy dokumentacji medycznej, np. Informacja dla lekarza
          kierującego / POZ
        </li>
      </ul>
      <p>
        18. Dostarczona dokumentacja każdorazowo jest weryfikowana pod względem
        kompletności, czytelności i jej aktualności przez Lekarza udzielającego
        usługi. W przypadku istnienia wątpliwości medycznych Lekarz może
        poprosić o dostarczenie dodatkowej dokumentacji medycznej lub odmówić
        wystawienia recepty lub konsultacji w określonym zakresie, umieszczając
        odpowiednie zapisy w dokumentacji medycznej usługi.
      </p>
      <p>19. Niewystawienie recepty przez Konsultanta Medycznego</p>
      <ul>
        <li>a) związane z występowaniem u Uprawnionego przeciwwskazań;</li>
        <li>b) związane z brakiem wskazań do wystawienia recepty;</li>
        <li>
          c) w wyniku nieprzedstawienia przez Uprawnionego dokumentacji
          medycznej; nie może stanowić podstawy reklamacji.
        </li>
      </ul>
      <p>
        20. Konsultant Medyczny w razie potrzeby może zalecić badanie fizykalne
        przez inną osobę wykonującą zawód medyczny.
      </p>
      <p>
        21. Konsultanci Medyczni posiadają wszelkie wymagane przepisami prawa
        uprawnienia i kwalifikacje do udzielania Świadczeń Telemedycznych.
      </p>
      <p>
        22. Konsultanci Medyczni prowadzą dokumentację medyczną zgodnie z
        przepisami prawa, w szczególności zgodnie z ustawą o prawach pacjenta i
        Rzeczniku Praw Pacjenta.
      </p>
      <p>
        23. Konsultacja może być poprzedzona przeprowadzeniem ankiety dotyczącej
        m.in. ogólnego stanu zdrowia Uprawnionego, dotychczasowego leczenia,
        przyjmowanych leków czy predyspozycji genetycznych.
      </p>
      <p>
        24. Konsultant Medyczny przeprowadza badanie podmiotowe Uprawnionego,
        które ma na celu ocenę stanu jego zdrowia oraz dobranie właściwych metod
        leczenia.
      </p>
      <p>
        25. Uprawniony zobowiązuje się przekazać Konsultantowi Medycznemu
        wszelkie informacje i okoliczności, które mogą mieć znaczenie dla
        świadczenia Usługi Telemedycznej, w tym kopie dokumentacji medycznej,
        wyników badań, które mogą być konieczne.
      </p>
      <p>26. Usługi Telemedyczne nie obejmują swoim zakresem:</p>
      <ul>
        <li>
          - wystawiania skierowań na usługi specjalistyczne z zakresu
          diagnostyki: badań radiologicznych, tomografii komputerowej, rezonansu
          magnetycznego oraz badań endoskopowych;
        </li>
        <li>- leczenia niepłodności;</li>
        <li>
          - leczenia zatrucia spowodowanego spożywaniem alkoholu, używaniem
          narkotyków i innych substancji uznanych za nielegalne oraz środków
          odurzających, zażyciem środków farmakologicznych niezapisanych przez
          lekarza lub samowolnym przekroczeniem dawek lekarstw powszechnie
          stosowanych w lecznictwie;
        </li>
        <li>- leczenia uzależnień;</li>
        <li>- leczenia związanego ze skutkami próby samobójczej;</li>
        <li>
          - leczenia innych chorób i stanów, które w swojej specyfice lub ze
          względu na dobro pacjenta wymagają osobistego zbadania pacjenta w
          gabinecie lekarskim;
        </li>
        <li>
          - leczenia schorzeń i stanów przewlekłych, których dokumentacja
          medyczna nie zostanie udostępniona przez Uprawnionego lub PZU Zdrowie.
        </li>
      </ul>
      <p>
        27. Usługi medyczne oferowane przez Świadczeniodawcę za pośrednictwem
        Portalu NIE SĄ PRZEZNACZONE dla osób:
      </p>
      <ul>
        <li>a) wymagających hospitalizacji;</li>
        <li>
          b) w stanie bezpośredniego zagrożenia życia lub zdrowia (np. pacjent
          nieprzytomny, nie oddycha, pacjent ma nagłą reakcję alergiczną,
          pacjent doznał niekontrolowanego krwawienia), w szczególności w
          sytuacji:
          <ul>
            <li>– utraty przytomności;</li>
            <li>– nagłych zaburzeń świadomości;</li>
            <li>– agresji spowodowanej chorobą psychiczną;</li>
            <li>– dokonanej próby samobójczej;</li>
            <li>– drgawek;</li>
            <li>– nagłego, ostrego bólu w klatce piersiowej;</li>
            <li>– zaburzeń rytmu serca;</li>
            <li>– nagłej nasilonej duszności;</li>
            <li>– nagłego ostrego bólu brzucha;</li>
            <li>– uporczywych wymiotów;</li>
            <li>
              – ostrej i nasilonej reakcji uczuleniowej (wysypka z nasiloną
              dusznością), będącej efektem zażycia leku, ukąszenia czy użądlenia
              przez jadowite zwierzęta;
            </li>
            <li>– zatruć lekami, środkami chemicznymi czy gazami;</li>
            <li>– rozległych oparzeń;</li>
            <li>– udarów cieplnych;</li>
            <li>– wyziębienia organizmu;</li>
            <li>– złamań, upadków z wysokości;</li>
            <li>– wypadków komunikacyjnych;</li>
            <li>– urazów;</li>
            <li>– rozległej rany, będącej efektem urazu;</li>
            <li>– porażenia prądem elektrycznym;</li>
            <li>– podtopienia lub utonięcia;</li>
            <li>– porodu i połogu.</li>
          </ul>
        </li>
        <li>
          c) w innych stanach wymagających niezwłocznego wezwania pogotowia
          ratunkowego;
        </li>
      </ul>
      <p>
        28. Uprawniony ponosi wyłączną odpowiedzialność za niezgodne z prawdą
        lub zatajone informacje, o których mowa w ustępie poprzedzającym, jeżeli
        miały lub mogły mieć wpływ na wykonanie Usługi Telemedycznej.
      </p>
      <p>
        29. W przypadku uzyskania podczas komunikacji z Uprawnionym wiarygodnej
        informacji, że wymagane jest udzielenie natychmiastowej pomocy,
        Konsultant Medyczny udziela Uprawnionemu pomocy dostępnymi środkami i z
        uwzględnieniem okoliczności, że świadczenia zdrowotne udzielane są drogą
        elektroniczną. Jeżeli Uprawniony nie wyraził sprzeciwu i ustalony został
        adres, pod którym Uprawniony aktualnie przebywa, Konsultant Medyczny
        może skontaktować się z numerem alarmowym 112 i wezwać pogotowie, chyba
        że okoliczności wskazują, że wezwanie pogotowia nie jest konieczne.
      </p>
      <p>
        30. Przebieg świadczenia Usług Telemedycznych może być rejestrowany
        przez Konsultanta Medycznego, a jego zapis może być przechowywany na
        serwerach wskazanych przez Konsultanta. Akceptując Regulamin Uprawniony
        wyraża zgodę na rejestrację przebiegu Usług Telemedycznych, w tym na
        sporządzanie zapisu głosowego lub audiowizualnego.
      </p>

      <h3>§ 4. Wymogi techniczne</h3>

      <p>
        1. Korzystanie z Portalu wymaga spełnienia następujących wymagań
        technicznych, niezbędnych do współpracy z Systemem Teleinformatycznym,
        którym posługuje się Świadczeniodawca:
      </p>
      <ul>
        <li>
          a. urządzenie z dostępem do sieci Internet z systemem operacyjnym
          Microsoft Windows, MacOS, iOS lub Android i minimalną prędkością łącza
          1 Mbps,
        </li>
        <li>
          b. zainstalowana aktualna wersja przeglądarki internetowej Chrome,
          Edge, Safari lub Firefox z włączoną obsługą aplikacji niezbędnych do
          poprawnego połączenia z systemem teleinformatycznym,
        </li>
        <li>
          c. uruchomienie obsługi JavaScript (najnowszy i włączony JavaScript),
        </li>
        <li>d. aktywny adres e-mail,</li>
        <li>e. telefon stacjonarny lub komórkowy.</li>
      </ul>
      <p>
        2. W przypadku korzystania z Portalu na urządzeniach mobilnych,
        urządzenie powinno posiadać system Android, iOS zaktualizowany do
        najnowszej wersji.
      </p>
      <p>
        3. W przypadku korzystania z Portalu na urządzeniach mobilnych nie jest
        wymagana instalacja aplikacji, rozwiązanie pracuje w standardzie Web
        Progressive App na urządzeniach mobilnych.
      </p>
      <p>
        4. Połączenie z Portalem odbywa się z wykorzystaniem protokołu SSL.
      </p>
      <p>
        5. W celu zapewnienia bezpieczeństwa świadczenia Usług Telemedycznych
        oraz przesyłu danych, Świadczeniodawca podejmuje środki, w szczególności
        techniczne, adekwatne do zagrożenia.
      </p>
      <p>
        6. Uprawniony ponosi wyłączną odpowiedzialność za niewykonanie lub
        nieprawidłowe wykonanie Usługi Telemedycznej, wynikające z niespełnienia
        przez Uprawnionego wymagań, określonych w Regulaminie.
      </p>

      <h3>§ 5. Reklamacje</h3>

      <p>
        1. Uprawnionemu przysługuje prawo zgłaszania zapytań, uwag i reklamacji
        w związku ze świadczeniem Usługi Telemedycznej na zasadach opisanych w
        umowie ubezpieczenia, abonamentu medycznego lub innej umowie, na
        podstawie której Uprawniony korzysta z Usług Telemedycznych.
      </p>
      <p>2. Treść reklamacji powinna zawierać, co najmniej:</p>
      <ul>
        <li>
          a. dane pozwalające na identyfikację Uprawnionego: imię i nazwisko,
          PESEL, adres e-mail lub adres korespondencyjny, na który ma być
          wysłana odpowiedź,
        </li>
        <li>b. określenie przedmiotu reklamacji,</li>
        <li>
          c. wskazanie daty zaistnienia zdarzenia świadczącego o nienależytym
          wykonaniu Usługi Telemedycznej.
        </li>
      </ul>
      <p>
        3. Rozpatrzenie zapytań, uwag i reklamacji, dotyczących działania
        Portalu i związanych z nimi kwestii technicznych następuje w najszybszym
        możliwym terminie, nie później jednak niż w terminie 14 dni roboczych.
      </p>
      <p>
        4. Odpowiedź na reklamację zostanie wysłana na adres korespondencyjny
        lub na wniosek Uprawnionego pocztą elektroniczną.
      </p>

      <h3>§ 6. Zasady gromadzenia i przetwarzania danych</h3>

      <p>
        1. Uprawniony ma prawo dostępu do swoich danych osobowych, ich
        poprawiania oraz żądania ich usunięcia, z zastrzeżeniem powszechnie
        obowiązujących przepisów prawa regulujących zasady przechowywania
        dokumentacji medycznej i danych medycznych. Dane Uprawnionego utrwalone
        w ramach wykonywania Usługi Telemedycznej poprzez Portal dostępne są po
        zalogowaniu do Indywidualnego Konta Uprawnionego.
      </p>
      <p>
        2. Świadczeniodawca zabezpiecza świadczenie Usług Telemedycznych oraz
        transfer danych przed dostępem osób trzecich, zgodnie z wymaganiami
        przepisów Ustawy o ochronie danych osobowych oraz aktów wykonawczych.
      </p>
      <p>
        3. Administratorem danych osobowych Uprawnionego zamieszczonych w
        Portalu niezbędnych do świadczenia Usługi Telemedycznej, jest Home
        Doctor.
      </p>
      <p>
        4. Świadczeniodawca przetwarza dane osobowe w celu realizacji Usługi
        telemedycznej na podstawie przepisów prawa w związku z zawartą umową.
      </p>
      <p>
        5. Z administratorem danych można się skontaktować poprzez adres e-mail
        kontakt@homedoctor.pl.
      </p>
      <p>
        6. Administrator wyznaczył Inspektora Ochrony Danych, Pawła Mellera,
        informatykaMEDYCZNA.pl ul. Twarda 18 00-105 Warszawa NIP:774-240-93-37.
        Z Inspektorem Ochrony Danych można skontaktować się pod numerem
        telefonu: 22 398 70 64 lub adresem e-mail: iodo@informatykaMEDYCZNA.pl.
      </p>
      <p>
        7. Dane osobowe są przetwarzane w celu świadczenia Usługi Telemedycznej
        zgodnie z Regulaminem.
      </p>
      <p>
        8. Dane osobowe, w tym dane wrażliwe o stanie zdrowia Uprawnionego, będą
        przetwarzane zgodnie z obowiązującymi przepisami prawa, w szczególności
        ustawy o ochronie danych osobowych Ustawy o prawach pacjenta i Rzeczniku
        Praw Pacjenta oraz ustawy o świadczeniu usług drogą elektroniczną.
      </p>
      <p>
        9. Uprawniony wyraża zgodę na udostępnianie swoich danych, w tym danych
        zawartych w dokumentacji medycznej za pośrednictwem Portalu, podmiotom
        wykonującym działalność leczniczą, w ramach których działają Konsultanci
        Medyczni oraz PZU Zdrowie SA, w zakresie, w jakim jest to niezbędne do
        wykonania i rozliczenia Usług Telemedycznych oraz kontynuacji leczenia
        Uprawnionego zgodnie z niniejszym Regulaminem.
      </p>
      <p>
        10. Dane osobowe mogą być przekazywane podmiotom przetwarzającym dane
        osobowe na zlecenie administratora (m.in. dostawcom usług IT, agencjom
        marketingowym) - przy czym takie podmioty przetwarzały będą dane na
        podstawie umowy z administratorem i wyłącznie z polecenia
        administratora.
      </p>
      <p>
        11. Podanie danych osobowych przez Uprawnionego wynika z ustawy o
        prawach pacjenta i Rzeczniku Praw Pacjenta, zaś podanie numeru telefonu
        jest niezbędne do komunikacji przy użyciu łącza telefonicznego.
      </p>

      <h3>§ 7. Postanowienia końcowe</h3>

      <p>
        1. Uprawniony ponosi koszty wynikające z korzystania ze środków
        komunikacji elektronicznej, niezbędnych do skorzystania z Usług
        Telemedycznych wg cennika operatora, świadczącego na rzecz Uprawnionego
        usługi telekomunikacyjne.
      </p>
      <p>
        2. Przesyłanie treści niezgodnych z prawem, sprzecznych z dobrymi
        obyczajami lub naruszających prawa Świadczeniodawcy lub innych oraz
        które mogłyby powodować lub zachęcać do zachowania uznawanego za
        działanie niezgodne z prawem, naruszającego prawa osób trzecich, w
        szczególności prawa autorskie lub dobra osobiste, jest zabronione.
      </p>
      <p>
        3. Świadczeniodawca poinformuje Uprawnionego o wszelkich zmianach
        Regulaminu niezwłocznie po zalogowaniu się Uprawnionego na Portalu. W
        celu dalszego korzystania z Usług Telemedycznych niezbędna jest
        akceptacja zmian Regulaminu przez Uprawnionego.
      </p>
      <p>
        4. Umowa zawierana na zasadach określonych w Regulaminie jest umową
        dotyczącą usług zdrowotnych w rozumieniu art. 3 pkt. 7 ustawy o prawach
        konsumenta.
      </p>
      <p>
        5. Złożenie za pośrednictwem PZU Zdrowie wniosku o utworzenie
        Indywidualnego Konta Uprawnionego jest równoznaczne ze złożeniem przez
        Uprawnionego oświadczenia, że:
      </p>
      <ul>
        <li>
          a. Uprawniony ukończył 18 lat oraz posiada pełną zdolność do czynności
          prawnych;
        </li>
        <li>
          b. wskazany przez Uprawnionego adres e-mail należy do Uprawnionego, a
          jego podanie nie narusza praw osób trzecich.
        </li>
        <li>
          c. Utrwalanie, zabezpieczanie i udostępnianie Uprawnionemu treści
          związanych z wykonaniem umowy w zakresie Usług Telemedycznych
          następuje przy użyciu środków komunikacji elektronicznej.
        </li>
      </ul>
      <p>
        6. Uprawniony może rozwiązać umowę ze Świadczeniodawcą w zakresie
        dostępu do Portalu i świadczenia Usług Telemedycznych w każdej chwili,
        składając w tym celu oświadczenie w sposób przewidziany dla składania
        reklamacji, chyba że postanowienia odrębnej umowy dotyczącej świadczeń
        zdrowotnych na rzecz Uprawnionego stanowią inaczej.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegulationText",
  inject: ["mode"],
  computed: {
    companyName() {
      switch (this.mode) {
        case "GENERAL": {
          return "Doc4You";
        }
        case "PZU": {
          return "PZU ZDROWIE";
        }
      }
      return "Doc4You";
    },
  },
};
</script>

<style lang="scss" scoped>
.regulation-text {
  h3 {
    font-weight: bold;
  }


  h3,
  p {
    font-size: 14px;
    margin-right: 20px;
  }
  *:last-child {
    margin-bottom: 0;
  }

  ul {
    list-style: none;
  }
}
</style>
